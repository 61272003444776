import { Dropdown, Table, Form, Button, InputGroup, Badge } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  ChartPieSliceTitleIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  ExcelIcon,
  PDFIcon,
  DownloadIcon,
  EditIcon,
  DeleteIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useCurrencies,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
  useSingleAndDoubleClick,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  allAcountDetailIDs,
  customerFullName,
  defaultSelectValue,
  fetchActionsUtil,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  scrollToTop,
} from "../../utils/helpers";
import RsDateRangePicker from "./../utils/RsDateRangePicker";
import queryString from "query-string";
import { Link, useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useAuth } from "../../hooks/useAuth";
import PageHeader from "../PageHeader";
import AddAccounts from "../modals/AddAccounts";
import { toUpper } from "lodash";
import DamagesAccountModal from "../DamageAccountModal";
import { IsPrivileged } from "../DisplayChildElement";

// ----------------------------------------------------
import { CSVLink } from "react-csv";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import { isEmpty } from "lodash";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { read, utils } from "xlsx";
import ConfirmDialog from "../ConfirmDialogue";
import * as yup from "yup";
import { toast } from "react-toastify";
import EditAccounts from "../modals/EditAccounts";
import DeleteAccounts from "../modals/DeleteAccounts";

export default function ChartOfAccountReport() {
  useScrollTop();
  const navigate = useNavigate();

  const initialFilterParams = {
    usage: "chart of accounts",
    calculateRunningBalance: true,
    Type: "",
  };

  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
  } = useCurrencies();
  const { backendUrl, token } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [showCreateNewAccountModal, setShowCreateNewAccountModal] = useState(
    false
  );
  const [selectedAccountToEdit, setSelectedAccountToEdit] = useState()
  const [selectedAccountToDelete, setSelectedAccountToDelete] = useState()

  const [excelData, setExcelData] = useState([]);
  const CSVLinkRef = useRef(null);
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
    currency: "",
    convertCurrency: prevailingCurrency,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const getExpenses = async (debouncedqueryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/journal/journal-list?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.accountTypes = [
      {
        label: "All Account Types",
        value: "",
      },
      ...data.accountTypes.map((el) => ({
        ...el,
        label: toUpper(el.Type),
        value: el.Type,
      })),
    ];
    data.accountDetailTypes = [
      {
        label: "All Detail Types",
        value: "",
      },
      ...data.accountDetailTypes.map((el) => ({
        ...el,
        label: toUpper(el.DetailType),
        value: el.DetailType,
      })),
    ];
    return data;
  };

  const { data = { count: 0, records: [] }, refetch } = useQuery(
    [reportActions.SALES_INVENTORY, queryParams],
    () => getExpenses(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const { limit, page, ...rest } = queryParams;
  const newQuery = {
    ...rest,
  };
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/journal/journal-list?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    const date = "";

    let records = exData.data.records;
    let dataForExcel = [];

    for (let el of records) {
      //    console.log(el);
      const title = [
        `${findRange(el?.AccountType)[0] || ""} - ${el?.AccountType || ""}`,
        "",
        "",
        "",
      ];

      if (
        !isEmpty(el.subAccounts) &&
        el.subAccounts.find((el) => !isEmpty(el.subAccountsRecords))
      ) {
        if (!isEmpty(title)) {
          dataForExcel.push(title);
        }
        for (let sub of el.subAccounts) {
          if (!isEmpty(sub.subAccountsRecords)) {
            if (sub?.AccountType || sub?.AccountDesc) {
              dataForExcel.push([
                `${sub?.AccountType || ""} - ${sub?.AccountDesc || ""}`,
                "",
                "",
                "",
              ]);
            }

            dataForExcel.push([
              "Account ID",
              "Title",
              "Account Type",
              "Balance",
            ]);

            for (let subAcc of sub.subAccountsRecords) {
              dataForExcel.push([
                subAcc.AccountID,
                subAcc.Description,
                subAcc.accountTypeForReport,
                currency(subAcc.Balance, {
                  symbol: "",
                }).format(),
              ]);
            }
          }
        }
      }
    }

    const headers = [];

    exData = [
      [company],
      ["Chart of Accounts"],
      [date],
      [""],
      headers,
      ...dataForExcel,
      [""],
    ];

    //  console.log(exData);
    setExcelData(exData);
  };

  const openList = (e, el) => {
    if (e.detail === 2) {
      navigate(
        `/account-setup/account-list?AccountID=${el?.AccountID
        }&Description=${encodeURIComponent(el?.Description)}`
      );
    }
  };

  const findRange = (account) => {
    const data = [];

    allAcountDetailIDs.every((el) => {
      if (el.data.some((d) => d === account)) {
        data.push(Number(el.num1), Number(el.num2));
        return false;
      }
      return true;
    });

    return data;
  };

  const addStockAccountList = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/invexcloud/add-accounts`, "POST", token, payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        if (refetch) refetch();
      },
      onError: (error) => {
        console.log(error)
        toast.error(`Unable to perform action: ${error}`);
      },
    }
  );

  function handleFile({ eventData: e, type }) {
    /*  toast.info("Service not Available");
    return; */
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        const workbook = read(e.target.result, {
          cellDates: true,
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });
        let rows = utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            defval: "",
            dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
          }
        );

        //--
        if (
          !(await ConfirmDialog({
            title: "Are you sure?",
            description: "You want to import",
          }))
        ) {
          return;
        }


        let schema = yup.object().shape({
          items: yup.array().of(
            yup.object({
              Account_ID: yup.string(),
              Account_Name: yup.string().required(),
              // Description: yup.string().required(),
              Account_Type: yup.string().required(),
              Type_Detail: yup.string().required(),
            })
          ),
        });
        try {
          await schema.validate({ items: rows });

          // Clean Up
          rows = rows.map((el) => ({
            AccountID: String(el.Account_ID),
            Description: el.Account_Name,
            Account_Desc: el.Account_Name,
            Type: el.Account_Type.toUpperCase(),
            DetailType: el.Type_Detail.toUpperCase(),
          }));

          addStockAccountList.mutate({ accounts: rows });
        } catch (err) {
          console.log(err);
          toast.error(err.name);
          toast.error(JSON.stringify(err.errors));
        }
      };
      reader.readAsArrayBuffer(file);
    }
    e.target.value = "";
  }

  function handleEdit(subAcc) {
    return String(subAcc.AccountID).length <= 3 ? toast.error('Built-in Accounts cannot be modifled') : setSelectedAccountToEdit(subAcc)
  }
  function handleDelete(subAcc) {
    return String(subAcc.AccountID).length <= 3 ? toast.error('Built-in Accounts cannot be modifled') : setSelectedAccountToDelete(subAcc)
  }

  return (
    <IsPrivileged roleName="Chart of Account">
      <main className="inner-page-outlet">
        <PageHeader
          name="Chart of Accounts"
          description="Account List"
          icon={<ChartPieSliceTitleIcon />}
        />

        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className="content-body">
                <header className="align-items-start pt-5">
                  <div className="d-flex gap-3 flex-grow-1 align-items-center z-10">
                    <h1>
                      {!showFilter && (
                        <button
                          onClick={() => setShowFilter(!showFilter)}
                          className="btn filter"
                        >
                          <FilterTwoIcon />
                        </button>
                      )}
                      Chart of Accounts
                      <button
                        onClick={() => refetch()}
                        className="btn text-primary"
                      >
                        <CachedIcon />
                      </button>
                    </h1>

                    <div className="global-search-area col-md-3 text-nowrap">
                      <MagnifyIcon />
                      <Form.Control
                        className=""
                        name="Description"
                        value={filterParams.Description}
                        onChange={(e) => handleFilterParamsChange(e)}
                        placeholder="Search Description..."
                      />
                    </div>
                    <div className="col-md-2">
                      <Select
                        classNamePrefix={"form-select"}
                        options={data?.accountTypes}
                        value={data?.accountTypes?.find(
                          (el) => el.value === filterParams.Type
                        )}
                        onChange={(selected) =>
                          setFilterParams({
                            ...filterParams,
                            page: 1,
                            Type: selected.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-2">
                      <Select
                        classNamePrefix={"form-select"}
                        options={data?.accountDetailTypes}
                        value={data?.accountDetailTypes?.find(
                          (el) => el.value === filterParams.DetailType
                        )}
                        onChange={(selected) =>
                          setFilterParams({
                            ...filterParams,
                            page: 1,
                            DetailType: selected.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="actions mr-5 flex-wrap justify-content-end">
                    <Form.Group className="position-relative">
                      <Form.Label className="fw-5 position-absolute bottom-100 start-0 m-1">
                        Filter By
                      </Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="All Currencies"
                        isSearchable={true}
                        options={currenciesOptions}
                        value={currenciesOptions.find(
                          (el) => el.value == filterParams.currency
                        )}
                        onChange={(selected) =>
                          setFilterParams({
                            ...filterParams,
                            page: 1,
                            currency: selected?.value,
                          })
                        }
                        isClearable={true}
                        menuPosition="fixed"
                      />
                    </Form.Group>
                    <Form.Group className="position-relative">
                      <Form.Label className="fw-5 position-absolute bottom-100 start-0 m-1">
                        Report In{" "}
                      </Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select Currency"
                        isSearchable={true}
                        options={currenciesOptions}
                        value={currenciesOptions.find(
                          (el) => el.value === filterParams.convertCurrency
                        )}
                        onChange={({ value }) =>
                          setFilterParams({
                            ...filterParams,
                            page: 1,
                            convertCurrency: value,
                          })
                        }
                        menuPosition="fixed"
                      />
                    </Form.Group>{" "}
                    <br />
                    <button
                      onClick={() => setShowCreateNewAccountModal(true)}
                      className="btn btn-primary"
                    >
                      Create New
                    </button>
                    <Link
                      to={"/account-setup/chart-of-account-report"}
                      className="btn btn-light-blue"
                    >
                      Show Running Balance
                    </Link>
                    <CSVLink
                      className="btn print d-none"
                      filename={`Chart_of_Accounts_(${format(
                        new Date(),
                        "dd-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="btn print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        Export
                        <ExportIcon color="#008000" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item
                          as="div"
                          onClick={onDownloadExcelData}
                          className="p-cursor"
                        >
                          Excel <ExcelIcon color="#008000" />
                        </Dropdown.Item>
                        <Dropdown.Item as="div">
                          <a
                            href={`${backendUrl}/api/journal/pdf/journal-list?${queryString.stringify(
                              newQuery
                            )}`}
                            target="blank"
                          >
                            PDF
                            <PDFIcon color="#ff0000" />
                          </a>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="btn print"
                        bsPrefix=""
                      >
                        <DotsVerticalIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="dropdown-with-icons"
                      >

                        <Dropdown.Item
                          as="div"
                        >
                          <a
                            href={`/excel_templates/INVEX_INSERT_ACCOUNTS_LIST_TEMPLATE.xlsx`}
                            target="blank"
                            title="Download"
                            download=""
                          >
                            <DownloadIcon /> Template
                          </a>
                        </Dropdown.Item>

                        <Dropdown.Item
                          as="label"
                          className="p-cursor"
                          title="Import"
                        >
                          <input
                            type="file"
                            className="d-none"
                            onChange={(e) =>
                              handleFile({ eventData: e, })
                            }
                            accept=".xlsx"
                          />
                          <ExcelIcon color="#008000" /> Import
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </header>

                <div className="px-4">
                  <h2 className="h5">
                    <b>CURRENT ASSET</b>
                  </h2>
                  {data?.records?.map((el, index) => (
                    <div className="mb-1" key={`${index}_${"1"}`}>
                      {!isEmpty(el.subAccounts) &&
                        el.subAccounts.find(
                          (el) => !isEmpty(el.subAccountsRecords)
                        ) ? (
                        <>
                          <p className="h5">
                            <b>
                              {" "}
                              {findRange(el.AccountType)[0]} - {el.AccountType}{" "}
                            </b>{" "}
                          </p>
                          {el.subAccounts?.map((sub) => (
                            <div className="mb-3" key={`${index}_${sub.AccountDesc}`}>
                              {isEmpty(sub.subAccountsRecords) ? null : (
                                <>
                                  <p className="mb-2">
                                    <b>
                                      {sub?.AccountType}
                                      {sub.AccountDesc
                                        ? ` - ${sub?.AccountDesc}`
                                        : ""}
                                    </b>{" "}
                                  </p>
                                  <Table
                                    borderless
                                    striped
                                    hover
                                    className="product-table"
                                  >
                                    <thead>
                                      <tr>
                                        <th scope="col" class="col-1">
                                          {""}
                                        </th>
                                        <th scope="col" class="col-1">
                                          {""}
                                        </th>
                                        <th scope="col" class="col-3">
                                          Title
                                        </th>
                                        <th scope="col" class="col-2">
                                          Account Type
                                        </th>
                                        <th scope="col" class="col-2">
                                          Balance
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {sub?.subAccountsRecords?.map(
                                        (subAcc) => (
                                          <tr
                                            key={`${index}_${subAcc.AccountID}`}
                                            className="p-cursor"
                                            onClick={(e) => openList(e, subAcc)}
                                          >
                                            <td>
                                              <Dropdown>
                                                <Dropdown.Toggle
                                                  variant=""
                                                  className="bg-white border-0"
                                                  bsPrefix="print more"
                                                >
                                                  <DotsVerticalIcon />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                  popperConfig={{
                                                    strategy: "fixed",
                                                  }}
                                                  renderOnMount
                                                  className="dropdown-with-icons"
                                                ><Dropdown.Item
                                                  as="button"
                                                  onClick={() => handleEdit(subAcc)}
                                                >
                                                    <EditIcon />
                                                    Edit
                                                  </Dropdown.Item>

                                                  <Dropdown.Item
                                                    as="button"
                                                    onClick={() => handleDelete(subAcc)}

                                                  >
                                                    <DeleteIcon />
                                                    Delete
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>{" "}
                                              </Dropdown>

                                            </td>
                                            <td>{subAcc.AccountID}</td>
                                            <td>{subAcc.Description}</td>
                                            <td>
                                              {subAcc.accountTypeForReport}
                                            </td>
                                            <td>
                                              {currency(subAcc.Balance, {
                                                symbol: "",
                                              }).format()}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </>
                              )}
                            </div>
                          ))}
                        </>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </main>

        {showCreateNewAccountModal && (
          <AddAccounts
            show={showCreateNewAccountModal}
            onHide={() => setShowCreateNewAccountModal(false)}
            refetch={() => refetch()}
          />
        )}

        {selectedAccountToEdit && (
          <EditAccounts
            selectedAccountToEdit={selectedAccountToEdit}
            show={selectedAccountToEdit}
            onHide={() => setSelectedAccountToEdit()}
            refetch={() => refetch()}
          />
        )}
        {selectedAccountToDelete && (
          <DeleteAccounts
            selectedAccountToDelete={selectedAccountToDelete}
            show={selectedAccountToDelete}
            onHide={() => setSelectedAccountToDelete()}
            refetch={() => refetch()}
          />
        )}
      </main>
    </IsPrivileged >
  );
}
